import React, { useState } from "react";
import "../../styles/Organisation.css";

const Organization = () => {
    const [hoveredCollege, setHoveredCollege] = useState(null);

    const handleMouseEnter = (college) => {
        setHoveredCollege(college);
    };

    const handleMouseLeave = () => {
        setHoveredCollege(null);
    };

    return (
        <div className="cpms-container">
            <section className="cpms-intro">
                <p>
                    Le CPMS est composé d’une centaine de <strong>membres</strong> – personnes morales et physiques -
                    <span className="highlight">adhèrent aux objectifs, messages et valeurs</span> du Collectif
                    inscrites dans le{" "}
                    <a
                        href={`/assets/doc2.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="document-link"
                    >
                        document de positionnement
                    </a>{" "}
                    « Reconnaissance et sécurisation du métier de médiateur.rice en santé »
                    publié en décembre 2022.
                </p>
                <p>
                    En complément des membres adhérents, le CPMS peut recueillir le
                    soutien de <span className="highlight">sympathisants</span>, personnes
                    physiques ou morales souhaitant <span className="highlight">s’associer ponctuellement</span> à une
                    prise de position, demande ou événement du
                    Collectif sans s’impliquer de manière active à son fonctionnement
                    régulier.
                </p>
                <img src="/assets/Picto-orga.png" alt="Study Image" className="image-membres"/>
                <h2>Représentativité</h2>
                <p>
                    Afin d’être représentatif de l’ensemble des acteur.rices de la
                    médiation en santé, le CPMS est composé de trois collèges :
                </p>
                <div className="colleges-container">
                    <div
                        className="college-box"
                        onMouseEnter={() => handleMouseEnter("structures")}
                        onMouseLeave={handleMouseLeave}
                    >
                        Collège médiateur.rices en santé
                    </div>
                    {hoveredCollege === "structures" && (
                        <div className="tooltip">
                            Le collège des médiateur.rices en santé est constitué des
                            personnes physiques qui, en raison de leur activité
                            professionnelle, souhaitent participer aux activités du CPMS.
                        </div>
                    )}

                    <div
                        className="college-box"
                        onMouseEnter={() => handleMouseEnter("mediators")}
                        onMouseLeave={handleMouseLeave}
                    >
                        Collège structures
                    </div>
                    {hoveredCollege === "mediators" && (
                        <div className="tooltip">
                            Le collège des structures est constitué des personnes morales qui,
                            en raison de leur activité et missions, souhaitent participer aux
                            activités du CPMS.
                        </div>
                    )}

                    <div
                        className="college-box"
                        onMouseEnter={() => handleMouseEnter("qualified")}
                        onMouseLeave={handleMouseLeave}
                    >
                        Collège Personnes qualifiées
                    </div>
                    {hoveredCollege === "qualified" && (
                        <div className="tooltip">
                            Le collège des personnes qualifiées est constitué des personnes
                            physiques qui, en raison de leur expérience ou de leur expertise,
                            souhaitent participer aux activités du CPMS.
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

export default Organization;
