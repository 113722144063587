import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import "../styles/SignUp.css";
import { useWindowSize } from 'react-use';
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SignUp = () => {
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [message, setMessage] = useState("");

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isPdf1Read, setIsPdf1Read] = useState(false);
    const [isPdf2Read, setIsPdf2Read] = useState(false);
    const [canProceed, setCanProceed] = useState(false);
    const [numPages1, setNumPages1] = useState(null);
    const [numPages2, setNumPages2] = useState(null);
    const { width } = useWindowSize();
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (width < 768) {
            setScale(0.8); // Ajuste le zoom pour mobile
        } else {
            setScale(1.3); // Zoom par défaut pour écran large
        }
    }, [width]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) setIsLoggedIn(true);
    }, []);

    useEffect(() => {
        setCanProceed(isPdf1Read && isPdf2Read);
    }, [isPdf1Read, isPdf2Read]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedOption) {
            setMessage("Veuillez sélectionner une option.");
            return;
        }
        if (!isPdf1Read || !isPdf2Read) {
            setMessage("Vous devez lire les documents avant de vous inscrire.");
            return;
        }
        if (!isChecked1) {
            setMessage("Vous devez accepter les termes et conditions avant de vous inscrire.");
            return;
        }

        const userData = { firstName, lastName, username, email, password, selectedOption };

        axios.post("http://192.162.70.175:4000/signup", userData)
            .then(() => setMessage("Inscription réussie ! Veuillez vérifier votre email et attendre l'approbation de l'administrateur."))
            .catch(() => setMessage("Erreur lors de l'inscription"));
    };

    const handleReadPdf1 = () => setIsPdf1Read(true);
    const handleReadPdf2 = () => setIsPdf2Read(true);

    const onDocumentLoadSuccess1 = ({ numPages }) => {
        setNumPages1(numPages - 4); // Removing the first 2 and last 2 pages
    };

    const onDocumentLoadSuccess2 = ({ numPages }) => {
        setNumPages2(numPages);
    };

    return (
        <div className="signup-wrapper">
            <div>
                {isLoggedIn ? (
                    <div>
                        <h2>Vous êtes déjà connecté</h2>
                        <p>Rendez-vous sur l'espace membre.</p>
                    </div>
                ) : (
                    <div>
                        <h2>Nous Rejoindre</h2>

                        {/* Section pour les documents PDF */}
                        <div className="pdf-section">
                            {!isPdf1Read ? (
                                <div className="pdf-item">
                                    <Document
                                        file="/assets/doc2.pdf"
                                        onLoadSuccess={onDocumentLoadSuccess1}
                                    >
                                        {Array.from(
                                            new Array(numPages1),
                                            (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 3} scale={scale}/> // Start from the third page
                                            )
                                        )}
                                    </Document>
                                    <button className="read-pdf-button" onClick={handleReadPdf1}>
                                        J'ai lu le PDF 1
                                    </button>
                                </div>
                            ) : !isPdf2Read ? (
                                <div className="pdf-item">
                                    <Document
                                        file="/assets/doc1.pdf"
                                        onLoadSuccess={onDocumentLoadSuccess2}
                                    >
                                        {Array.from(
                                            new Array(numPages2),
                                            (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1}
                                                      scale={scale}/>
                                            )
                                        )}
                                    </Document>
                                    <button className="read-pdf-button" onClick={handleReadPdf2}>
                                        J'ai lu le PDF 2
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        {canProceed && (
                            <form onSubmit={handleSubmit} className="signup-form">
                                <div className="form-group">
                                    <label htmlFor="firstName">Prénom</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Nom</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username">Nom de Structure</label>
                                    <input
                                        type="text"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="college">Veuillez choisir votre College</label>
                                    <select
                                        id="college"
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        required
                                    >
                                        <option value="">-- Sélectionnez une option --</option>
                                        <option value="College structure">College structure</option>
                                        <option value="College médiateur en santé">College médiateur en santé</option>
                                        <option value="College personne qualifiée">College personne qualifiée</option>
                                    </select>
                                </div>

                                {/* Section des termes et conditions */}
                                <div className="checkbox-container">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isChecked1}
                                            onChange={(e) => setIsChecked1(e.target.checked)}
                                            required
                                        />
                                        J'accepte les termes et conditions
                                    </label>
                                </div>

                                <button type="submit" className="signup-button">
                                    S'inscrire
                                </button>
                                <p className="signup-message">{message}</p>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignUp;