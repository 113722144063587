import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../styles/Agenda.css';

const Agenda = () => {
    const [date, setDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [eventTitle, setEventTitle] = useState('');
    const [eventMessage, setEventMessage] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        fetchEvents();
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token manquant. Veuillez vous reconnecter.");
                return;
            }
            const response = await axios.get('http://192.162.70.175:4000/groupes', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setGroups(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des groupes', error);
        }
    };

    const fetchEvents = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token manquant. Veuillez vous reconnecter.");
                return;
            }
            const response = await axios.get('http://192.162.70.175:4000/events', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setEvents(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des événements', error);
        }
    };

    const handleAddEvent = async (e) => {
        e.preventDefault();

        if (startTime >= endTime) {
            alert("L'heure de fin doit être après l'heure de début.");
            return;
        }

        if (eventTitle.trim() === '') return;

        if (selectedGroupId === '') {
            alert("Veuillez sélectionner un groupe de travail.");
            return;
        }

        const event = {
            title: eventTitle,
            message: eventMessage,
            date: date.toISOString(),
            startTime,
            endTime,
            groupId: selectedGroupId,
        };

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token manquant. Veuillez vous reconnecter.");
                return;
            }

            if (isEditing) {
                await axios.put(`http://192.162.70.175:4000/events/${currentEventId}`, event, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setIsEditing(false);
                setCurrentEventId(null);
            } else {
                await axios.post('http://192.162.70.175:4000/events', event, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchEvents();
        } catch (error) {
            console.error("Erreur lors de la création ou mise à jour de l'événement", error);
        }

        setEventTitle('');
        setEventMessage('');
        setStartTime('');
        setEndTime('');
        setSelectedGroupId('');
    };

    const handleDeleteEvent = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token manquant. Veuillez vous reconnecter.");
                return;
            }
            await axios.delete(`http://192.162.70.175:4000/events/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchEvents();
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'événement', error);
        }
    };

    const handleEditEvent = (event) => {
        setEventTitle(event.title);
        setEventMessage(event.message);
        setStartTime(event.startTime);
        setEndTime(event.endTime);
        setSelectedGroupId(event.groupId);
        setCurrentEventId(event._id);
        setIsEditing(true);
    };

    return (
        <div className="agenda-section">
            <h2>Agenda</h2>
            <div className="calendar-container">
                <Calendar onChange={setDate} value={date} />
                <p>Date sélectionnée : {date.toDateString()}</p>
            </div>
            <div className="add-event-form">
                <h3>{isEditing ? 'Modifier l\'événement' : 'Ajouter un événement'}</h3>
                <form onSubmit={handleAddEvent}>
                    <input
                        type="text"
                        placeholder="Titre de l'événement"
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                        required
                    />
                    <textarea
                        placeholder="Message de l'événement"
                        value={eventMessage}
                        onChange={(e) => setEventMessage(e.target.value)}
                        required
                    />
                    <div className="time-inputs">
                        <label>
                            Heure de début :
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Heure de fin :
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="group">Groupe de travail :</label>
                        <select
                            id="group"
                            value={selectedGroupId}
                            onChange={(e) => setSelectedGroupId(e.target.value)}
                            required
                        >
                            <option value="">-- Sélectionnez un groupe --</option>
                            {groups.map((group) => (
                                <option key={group._id} value={group._id}>
                                    {group.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button type="submit">{isEditing ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
            </div>
            <div className="events-list">
                <h3>Événements</h3>
                {events.length === 0 ? (
                    <p>Aucun événement pour le moment.</p>
                ) : (
                    <ul>
                        {events
                            .filter((event) => new Date(event.date).toDateString() === date.toDateString())
                            .map((event) => (
                                <li key={event._id}>
                                    <span>{event.title}</span> - <em>{new Date(event.date).toDateString()}</em> de {event.startTime} à {event.endTime} dans le groupe : {event.groupId?.name || event.groupId}
                                    <p>Message : {event.message}</p>
                                    {event.link && (
                                        <p>
                                            Lien associé : <a href={event.link} target="_blank" rel="noopener noreferrer">{event.link}</a>
                                        </p>
                                    )}
                                    <div className="event-actions">
                                        <button onClick={() => handleEditEvent(event)}>Modifier</button>
                                        <button onClick={() => handleDeleteEvent(event._id)}>Supprimer</button>
                                    </div>
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Agenda;
