import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/Header.css';

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [submenuOpen, setSubmenuOpen] = useState(null); // État pour les sous-menus

    const toggleSubmenu = (index) => {
        setSubmenuOpen(submenuOpen === index ? null : index); // Basculer l'état du sous-menu
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token && location.pathname !== '/login') {
            handleLogout();
        }
    }, [location, navigate, setIsLoggedIn]);

    // Mapper les noms des pages avec les chemins
    const pageTitles = {
        '/' : 'Accueil',
        '/qui-sommes-nous': 'Le Collectif',
        '/qui-sommes-nous/demandes': 'Nos demandes',
        '/qui-sommes-nous/membres': 'Nos membres',
        "/qui-sommes-nous/actions": 'Nos actions',
        '/mediation-en-sante': 'Médiation en santé',
        '/mediation-en-sante/definition': 'Qu’est-ce que la médiation en santé ?',
        '/mediation-en-sante/metier-mediateur': 'Médiateur en santé, un métier',
        '/mediation-en-sante/formations': 'Formations',
        '/mediation-en-sante/ressources': 'Ressources',
        '/mediation-en-sante/ressources/articles': 'Articles',
        '/mediation-en-sante/ressources/rapports': 'Rapport',
        '/mediation-en-sante/ressources/medias': 'Medias',
        '/preconisation': 'Etude C-PRO-MS au service du plaidoyer',
        '/preconisation/referentiel': 'Vers un référentiel métier',
        '/preconisation/formations': 'Vers une formation qualifiante',
        '/preconisation/accompagnement': 'Vers un accompagnement adapté',
        '/preconisation/deploiment': 'Vers un déploiement pérenne',
        '/contact': 'Contact',
        '/nous-rejoindre': 'Nous rejoindre',
        '/espace-membre/': 'Espace Membre',
        '/espace-membre/agenda': 'Agenda',
        '/espace-membre/documents': 'Documents',
        '/espace-membre/admin': 'Panels Administrateur',
        '/espace-membre/groupes': 'Groupes de travails',
        '/espace-membre/groupes/671978d2a0a8b651726a3b3a': 'COPIL',
        '/espace-membre/groupes/6724b1190da98a6a88f1d6ac': 'Assises',
        '/espace-membre/groupes/6724b1280da98a6a88f1d6ae': 'PRMS',
        '/espace-membre/groupes/6724b14b0da98a6a88f1d6b0': 'Remonté de terrain',
        '/espace-membre/groupes/6724b1510da98a6a88f1d6b2': 'Communication',
        '/espace-membre/groupes/671978d2a0a8b651726a3b3d': 'Réunion Pléniaire',
        '/login': 'Login',
    };

    // Obtenir le titre correspondant au chemin actuel
    const pageTitle = pageTitles[location.pathname];

    return (
        <>
        <header className="header header-pc">
            <div className="top-green-band">
                <div className="top-bar">
                    <img src="/assets/LOGO_degrade.svg" alt="Logo" className="logo"/>
                    <div className="right-links">
                        <a href="https://www.linkedin.com/company/collectif-pour-la-promotion-de-la-m%C3%A9diation-en-sant%C3%A9/posts/?feedView=all" className="social-link" target="_blank"
                           rel="noopener noreferrer">
                            <img src="/assets/logo-linkedin.webp" alt="LinkedIn" className="linkedin-logo"
                                 style={{width: '50px', height: '50px'}}/>
                        </a>
                        <Link to="/contact" className="top-link">Contact</Link>
                        {isLoggedIn && (
                            <Link to="/espace-membre/" className="top-link">Espace Membre</Link>
                        )}
                        {!isLoggedIn && (
                            <Link to="/nous-rejoindre" className="top-link">Nous Rejoindre</Link>
                        )}
                        {isLoggedIn ? (
                            <Link to="/login" onClick={handleLogout} className="top-link">Logout</Link>
                        ) : (
                            <Link to="/login" className="top-link">Login</Link>
                        )}
                    </div>
                </div>
            </div>
            <nav className="middle-white-band">
                <ul className="menu">
                    <li className="menu-item">
                        <Link to="/" className="menu-link">
                            <img src="/assets/LOGO_degrade.svg" alt="Accueil" className="menu-image"/>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/qui-sommes-nous" className="menu-link">Qui sommes-nous ?</Link>
                        <ul className="submenu">
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/membres" className="submenu-link">Nos membres</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/actions" className="submenu-link">Nos actions</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/demandes" className="submenu-link">Nos demandes</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <span className="menu-link disabled">Médiation en santé</span>
                        <ul className="submenu">
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/definition" className="submenu-link">Qu'est-ce que la
                                    médiation en santé ?</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/metier-mediateur" className="submenu-link">Médiateur en
                                    santé, un métier</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/ressources" className="submenu-link">Ressources</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <Link to="/preconisation" className="menu-link">Nos Préconisations </Link>
                        <ul className="submenu">
                            <li className="submenu-item">
                                <Link to="/preconisation/referentiel" className="submenu-link"> Vers un referentiel
                                    métier</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/formations" className="submenu-link"> Vers une formation
                                    qualifiante</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/accompagnement" className="submenu-link"> Vers un accompagnement
                                    adapté</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/deploiment" className="submenu-link"> Vers un déploiement
                                    pérenne</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div className="bottom-green-band">
                {/* Section dynamique du header */}
                <div className="dynamic-title">
                    <h1>{pageTitle}</h1>
                </div>
            </div>
        </header>
            {/* Header Mobile */}
            <header className="header header-mobile">
                <div className="top-green-band">
                    <Link to="/" className="menu-link">
                        <img src="/assets/LOGO_degrade.svg" alt="Accueil" className="logo"/>
                    </Link>
                    <Link to="/contact" className="top-link">Contact</Link>
                    {isLoggedIn && (
                        <Link to="/espace-membre/" className="top-link">Espace Membre</Link>
                    )}
                    {!isLoggedIn && (
                        <Link to="/nous-rejoindre" className="top-link">Nous Rejoindre</Link>
                    )}
                    {isLoggedIn ? (
                        <Link to="/login" onClick={handleLogout} className="top-link">Logout</Link>
                    ) : (
                        <Link to="/login" className="top-link">Login</Link>
                    )}
                </div>
                <ul className="menu">
                    <li className="menu-item">
                        <div className="menu-link" onClick={() => toggleSubmenu(1)}>Qui sommes-nous ?</div>
                        <ul className={`submenu ${submenuOpen === 1 ? 'open' : ''}`}>
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/membres" className="submenu-link"> Nos Membres</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/actions" className="submenu-link"> Nos Actions</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/qui-sommes-nous/demandes" className="submenu-link"> Nos Demandes</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link disabled" onClick={() => toggleSubmenu(2)}>Médiation en santé</div>
                        <ul className={`submenu ${submenuOpen === 2 ? 'open' : ''}`}>
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/definition" className="submenu-link">Qu’est-ce que la
                                    médiation ?</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/metier-mediateur" className="submenu-link">Médiateur, un
                                    métier</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/mediation-en-sante/ressources" className="submenu-link">Ressources</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <div className="menu-link" onClick={() => toggleSubmenu(3)}>Nos Préconisations</div>
                        <ul className={`submenu ${submenuOpen === 3 ? 'open' : ''}`}>
                            <li className="submenu-item">
                                <Link to="/preconisation/referentiel" className="submenu-link"
                                >Vers un referentiel métier</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/formations" className="submenu-link"
                                >Vers une formation qualifiante</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/accompagnement" className="submenu-link"
                                >Vers un accompagnement adapté</Link>
                            </li>
                            <li className="submenu-item">
                                <Link to="/preconisation/deploiment" className="submenu-link"
                                >Vers un déploiement pérenne</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="bottom-green-band">
                    {/* Section dynamique du header */}
                    <div className="dynamic-title">
                        <h1>{pageTitle}</h1>
                    </div>
                </div>
            </header>
        </>
    );
};
export default Header;
