import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Accueil.css';

const Accueil = () => {
    const cards = [
        {
            title: 'Nos recommendations',
            link: 'http://192.162.70.175/qui-sommes-nous/demandes', // Path to the PDF
            image: '/assets/Accueil2.png',
        },
        {
            title: 'Etude C-PRO-MS',
            link: 'http://192.162.70.175/preconisation', // Path to your document
            image: '/assets/Accueil1.png',
        },
        {
            title: "Titre de l'article 1",
            link: '/articles/article1', // Path to your internal page
            image: '/assets/transparent.webp',
        },
        {
            title: "Titre de l'article 2",
            link: 'https://example.com', // External webpage link
            image: '/assets/transparent.webp',
        },
        {
            title: "Titre de l'article 3",
            link: '/articles/article3', // Path to your internal page
            image: '/assets/transparent.webp',
        },
    ];

    return (
        <div className="accueil-container">
            <div className="cards-container">
                {cards.map((card, index) => (
                    card.link.startsWith('http') ? (
                        <a
                            key={index}
                            href={card.link}
                            rel="noopener noreferrer"
                            className="card"
                        >
                            <img src={card.image} alt={card.title} className="card-image" />
                            <div className="card-content">
                                <p className="card-title">{card.title}</p>
                            </div>
                        </a>
                    ) : card.link.endsWith('.pdf') ? (
                        <a
                            key={index}
                            href={card.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="card"
                        >
                            <img src={card.image} alt={card.title} className="card-image" />
                            <div className="card-content">
                                <p className="card-title">{card.title}</p>
                            </div>
                        </a>
                    ) : (
                        <Link
                            key={index}
                            to={card.link}
                            className="card"
                        >
                            <img src={card.image} alt={card.title} className="card-image" />
                            <div className="card-content">
                                <p className="card-title">{card.title}</p>
                            </div>
                        </Link>
                    )
                ))}
            </div>
        </div>
    );
};

export default Accueil;
